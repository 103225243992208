<template>
    <div>
        <b-card no-body>
            <b-card-header>
                <b-card-title>
                    База знаний
                </b-card-title>
                <div>
                <b-button 
                    pill 
                    variant="light" 
                    :to="{ name: 'admin-knowledge-categories'}"
                >Категории</b-button>
                <b-button 
                    class="ml-1"
                    pill 
                    variant="primary" 
                    :to="{ name: 'admin-knowledge-add', params: { categories: categories }}"
                >Пополнить базу знаний</b-button>
                </div>
            </b-card-header>
            
            <b-skeleton-table v-if='is_loading' animation="fade" :columns='7' :rows='5'>
            
            </b-skeleton-table>
            
            <b-table-simple v-else class="font-small-3 text-black" responsive>
                <b-thead>
                    <b-tr>
                        <b-td>#</b-td>
                        <b-td>Заголовок</b-td>
                        <b-td>Категория</b-td>
                        <b-td>Описание</b-td>
                        <b-td>Slug</b-td>
                        <b-td>Дата создания</b-td>
                        <b-td></b-td>
                    </b-tr>
                </b-thead>
                <b-tbody>
                    <b-tr v-for="(item, index) in items" :key="item.id">
                        <b-td class="text-black font-weight-bolder">{{ item.id }}</b-td>
                        <b-td class="text-black">{{ item.header }}</b-td>
                        <b-td class="text-black">{{ item.kncategory.title }}</b-td>
                        
                        <b-td class="text-black">{{ item.description }}</b-td>
                        <b-td class="text-black">{{ item.slug }}</b-td>
                    
                        <b-td class="text-black">{{ item.date }} {{ item.time }}</b-td>                      
                        <b-td>
                            <b-dropdown
                                size="sm"
                                right
                                no-caret   
                                variant="transparent"
                            >
                                <template #button-content>
                                    <feather-icon icon="MoreVerticalIcon" class="text-muted" size="22" />
                                </template>
                                <b-dropdown-item :to="{ name: 'admin-knowledge-update', params: { item: item, categories: categories } }">
                                    <feather-icon icon="EditIcon" class="text-primary" size="13" />
                                    <span class="text-primary"> Редактировать</span>
                                </b-dropdown-item>
                                <b-dropdown-item @click="preferDeleteAction(item, index)">
                                   <feather-icon icon="TrashIcon" class="text-danger" size="13" />
                                   <span class="text-danger"> Удалить</span>
                                </b-dropdown-item>
                            </b-dropdown>
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>
            <b-card-body class="d-flex justify-content-between pt-0 pb-0">
                <span class="font-weight-bolder">Всего: {{ total_count }}</span>

                <b-pagination-nav 
                    :value="current_page" 
                    :link-gen="linkGen" 
                    :number-of-pages="last_page" 
                    use-router 
                ></b-pagination-nav>
            </b-card-body>
        </b-card>
    </div>
</template>

<script>
    export default {

        data() {
            return {
                is_loading: true,
                items: [],
                current_page: 0,
                has_more_pages: false,
                last_page: 0,
                total_count: 0,
                categories: [],
            }
        },
        methods: {
            
            preferDeleteAction( item, index ) {
                
                this.$swal({
                    title: `Подтвердите действие`,
                    text: `Вы действительно хотите удалить пост базы знаний '${item.header}'?`,
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Удалить',
                    cancelButtonText: 'Отмена',
                    customClass: {
                      confirmButton: 'btn btn-danger',
                      cancelButton: 'btn btn-outline-secondary ml-1',
                    },
                    buttonsStyling: false,
                  }).then(result => {
                    if (result.value) {
                        this.$request.post("knowledge/delete", {
                            id: item.id
                        }).then( rsp => {
                            this.items.splice( index, 1 );
                            this.$swal({
                                icon: 'success',
                                title: item.header,
                                text: 'Пост успешно удален',
                                customClass: {
                                  confirmButton: 'btn btn-success',
                                },
                            });
                        });
                    }
                  });
                
                
            },
            
            linkGen( pageNum ) {
                return {
                    name: this.$route.name,
                    query: {
                        page: pageNum
                    }
                }        
            },
            
            get() {
                this.is_loading = true;

                this.$request.get("knowledge/list", this.$route.query ).then( rsp => {
                    this.items = rsp.items;
                    this.categories = rsp.categories;
                    this.total_count = rsp.total_count;
                    this.current_page = rsp.current_page;
                    this.last_page = rsp.last_page;
                    this.is_loading = false;
                });
            },

        },
        components: {
            
        },
        watch: {
            "$route.query" : function () {
                this.get();
            }
        },
        mounted() {
            this.get();
        }

    }

</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>